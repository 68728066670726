import React from 'react';

type Props = {
    className?: string;
};

export const MarvelRivalsIcon: React.FC<Props> = ({ className }) => {
    return (
        <svg viewBox="0 0 440.5 269.6" className={`icon${className ? className : ''}`}>
            <polygon points="145.3 104.3 76.4 77.9 47.8 189.7 132.1 158.5 83.6 164.9 99.7 98.6 145.3 104.3" />
            <polygon points="292.9 99.8 372.7 68.9 344.1 181.5 277.1 155.5 320 160.8 336.2 94.5 292.9 99.8" />
            <polygon points="79 47.4 216.4 47.4 303.8 141.9 191.2 138.2 342.2 212.7 205.2 212.7 116.7 117.5 230.4 122 79 47.4" />
        </svg>
    );
};
