import React from 'react';

type Props = {
    className?: string;
};

export const SplatoonIcon: React.FC<Props> = ({ className }) => {
    return (
        <svg className={`icon${className ? className : ''}`} viewBox="0 0 864 864">
            <g>
                <path
                    d="M468.4,222.5c9.5-6.3,15.1-15.3,16.4-26.7c0.8-7.3-3.8-14.8-9.7-17.4c-5.7-2.4-12.2-0.4-17.7,5.6
		c-5,5.3-6.4,11.9-7.5,19.2c1.6,5.2,2.5,10.7,4.8,15.5C457.7,225,462.8,226.2,468.4,222.5z"
                />
                <path
                    d="M692,566.2c-20.2-2.2-40.4-3.7-60.6-5.2c-9.5-0.7-18.9-2.1-27.4-6.8c-9.9-5.4-13.8-15.2-10.1-25.8
		c3.7-10.5,10.7-18.2,21.2-22.2c3-1.2,6.4-1.4,9.7-2c11.5-2.2,19.2-10.5,19.4-21c0.3-10.5-6.4-18.6-17.9-21.6
		c-1.9-0.5-3.9-0.9-5.8-1.3c-13.1-3.3-25.7-7.9-36.2-17c-12.4-10.7-14.5-26.4-3.9-38.7c5.1-5.9,11.2-11.6,18-15.2
		c15.3-8.2,31.4-15.1,47-22.7c3.9-1.9,8-4,11.2-6.8c9.7-8.5,7.8-22-3.6-27.8c-2.4-1.2-4.9-2-7.2-3.2c-4.9-2.5-6-5.2-3.9-10.2
		c1-2.2,2.4-4.2,3.9-6.1c9-11.1,19.3-20.8,32.2-27c14.7-7,29.8-13.2,44.9-19.4c26-10.7,51.4-22.5,72.7-41.3
		c10.4-9.2,19.1-19.8,21.6-33.9c1.8-9.9-4.4-18.4-14.2-20.5c-10.3-2.2-20.2-0.2-29.9,3.1c-19.2,6.7-35,18.3-48.7,33.2
		c-10,10.9-19.9,21.8-30.2,32.5C678.1,256,661,272.1,642,285.7c-19.7,14.1-39.9,27.5-60.3,40.7c-9.3,6.1-19.9,9.3-31.1,10.3
		c-16.7,1.5-26.2-8.3-23.6-24.8c0.7-4.8,2-9.6,4-14c12.3-27.9,30.3-51.7,53.2-71.6c14.9-13,29.1-26.4,39.9-43
		c6.9-10.6,13.4-21.3,14.6-34.3c1.7-17.5-11.8-27.7-28-20.9c-4.4,1.9-8.8,4.4-12.3,7.5c-14.6,12.8-25.9,28.3-34.3,45.7
		c-4.5,9.3-7.3,19.4-11.6,28.8c-12,26.4-30.5,47.9-51.6,67.2c-6.6,6.1-14.1,11.3-23.5,12.9c-7.5,1.3-12.9-1.4-16.4-8.2
		c-3.1-6.1-2.6-12.2-0.7-18.6c2.7-8.9-2.5-18-11.8-21.6c-7.9-3.1-15.1-0.1-19.8,8.4c-0.5,1-0.9,2-1.5,2.9c-2.9,4.7-7.8,6.7-13.1,5.7
		c-5.1-1-8.7-5.1-9.4-10.6c-0.2-2.2-0.1-4.4,0.2-6.6c1.7-13.8-9.5-23.5-23.3-19.9c-8,2-11.4,6.4-11.7,14.6c-0.2,3.9-0.4,8-1.5,11.7
		c-2.5,8-9.6,11.1-16.7,6.9c-4.6-2.7-9.4-6.4-11.9-10.9c-4.8-8.3-9.1-17.2-11.7-26.5c-6.4-22.7-7.6-46.1-7.6-69.5
		c0-16.5,0.7-33.1,0.8-49.6c0.1-11.9-3.6-22.8-10.8-32.1c-11.4-14.8-25.2-14.6-36,1c-1,1.4-1.7,3.1-2.4,4.7
		c-4.1,10.1-3.9,20.4-0.3,30.4c5,13.9,10.5,27.6,16.2,41.2c9.3,22.4,18.4,45,21.9,69.1c3,20.9,1.3,40.8-12.8,58.2
		c-12.3,15.3-33.2,21.7-49.7,13.6c-15-7.4-26.8-18.4-34-33.9c-3.6-7.8-7.3-15.6-11.7-23c-5.6-9.4-13.1-17.3-22.8-22.6
		c-13.3-7.2-24.8-0.4-24.8,14.7c0,12.2,4.3,23.3,11,33.4c7,10.5,17.4,17.4,26.8,25.4c15,12.8,26.1,28.2,30.7,47.5
		c2,8.3,20.5,53.7-17.9,47.2c-4.1-0.7-8.5-0.7-12.3-2.2c-22.8-8.9-43.6-20.7-59.8-39.9c-8-9.6-17.5-17.9-26.8-26.4
		c-5.8-5.4-13.3-7.8-21.1-8.5c-15.6-1.3-24.9,11.7-18.7,26.1c0.9,2,2,4,3.3,5.7c4.8,6.5,10.7,11.5,18.4,14.5
		c17.8,7,35.7,13.9,53.2,21.8c11.9,5.4,21.9,13.9,29.6,24.8c7.2,10.2,10.2,21.5,7.5,33.8c-1.8,8.6-7.2,13.9-16,14.1
		c-6.9,0.2-14-1.1-20.8-2.4c-7.1-1.4-14-4.2-21.1-5.5c-8.3-1.5-15.7,3.8-17,11c-1.4,7.2,3.9,14.3,12.5,15.8
		c4.3,0.7,8.8,0.3,13.2,0.1c5.5-0.1,11-0.9,16.5-0.5c8.7,0.7,17,3.1,23.8,8.8c6.4,5.3,5.5,10.5-2.1,13.9c-2.4,1.1-4.8,2.2-6.9,3.8
		c-6.3,4.8-6.6,9.1-1.4,14.9c0.7,0.8,1.6,1.6,2.3,2.4c8.1,9.2,5.9,21.1-4.7,27.5c-12,7.3-25.1,10.5-39.1,10.8
		c-7.5,0.2-15,0.1-22.5,0.3c-9.6,0.2-18.9,1.8-26.9,7.7c-10.7,8-10.3,23.3,0.8,30.1c5.3,3.3,11.2,3.3,16.6,1.2
		c7.8-2.9,15.7-6.1,22.6-10.7c18.4-12.5,38.3-16.5,60.3-14.6c20.3,1.8,32.1,18.6,32.3,35.5c0.1,12-2.8,23.2-8,33.8
		c-8.7,18.1-21.5,33.3-36.1,46.9c-16.6,15.6-34.2,29.8-54.5,40.5c-16.1,8.6-32.4,17.2-46.2,29.4c-10.6,9.3-19.4,20-22,34.4
		c-1.7,9.4,4.7,17.5,14,15.9c6.9-1.2,13.9-4.2,20.1-7.7c16.2-9.1,27.1-23.6,37.5-38.5c15.1-21.7,33.1-40.7,53.3-57.6
		c8.1-6.8,17.4-11.3,28.3-11.6c12-0.3,19.6,4.9,21.2,15.5c0.2,1.5,0.8,3,1.5,4.4c2.6,5.4,6.6,7.5,12.4,5.9c2.9-0.8,6-2,8.3-3.8
		c8.2-6.6,16.1-13.6,24.3-20.4c4.2-3.5,8.2-7.4,12.9-10.2c7.9-4.7,14.5-1.7,16.1,7.4c0.8,4.7,0.9,9.7,0.3,14.5
		c-1.5,12.9-4,25.7-5.1,38.6c-1,11.6,9.6,18.4,20.5,13.9c6.7-2.8,11.2-7.7,13.7-14.4c1.6-4.1,2.5-8.6,4.8-12.3
		c2.6-4.2,5.6-8.7,9.6-11.4c21.3-14.4,46.7-3.5,51.8,21.6c1,4.9,2.5,9.9,4.7,14.3c2.9,5.6,9.5,7.6,15.5,5.7
		c5.4-1.7,8.9-7.3,8.4-13.5c-0.1-2-0.7-3.9-0.8-5.9c0-3.6,17-55.1,41.6,32.3c2.6,9.3,7.5,17.4,13.4,24.9c5.7,7.2,17.6,10.5,25.1,7.3
		c7.5-3.1,10.7-10.9,9.5-21.9c-1-8.6-4.9-15.8-9.3-23.1c-3.4-5.6-7.2-11.5-8.3-17.7c-3.1-18.4,3.6-33.6,18.9-44.2
		c15.1-10.5,31.1-9.7,46.8-0.9c17.9,10.1,30.7,25.6,42.7,41.8c13.6,18.4,26.9,37.1,41.1,55.1c8.5,10.8,19.6,19.1,33.1,23.3
		c16.5,5.1,29.7-5.7,27.4-22.8c-1.5-11.3-5.9-21.5-15.4-28.3c-13.1-9.3-26.6-18-40.2-26.7c-17.4-11.2-36.4-19.9-52.3-33.5
		c-14.8-12.8-27-27.4-32.2-46.6c-5.7-21.3-0.7-40.1,15.9-54.6c16.2-14.1,35.1-16.8,54.9-8c7.4,3.3,14.3,7.8,21.5,11.6
		c14.8,7.9,29.4,16.1,46.2,19.4c8.6,1.7,17.1,1.8,25.3-1.9c13.2-6,15.5-19.2,5.1-29.5C710.5,571.9,701.9,567.3,692,566.2z
		 M312.5,543.7c4.1,2,8.1,4.2,12.3,5.7c16.1,5.9,32.4,11.7,48.6,17.5c-3.4,3.3-7.6,5.1-13.6,3.2
		C342.2,564.5,326.7,555.4,312.5,543.7z M330.6,645c23.7,15.4,49.3,19.2,76.6,13.5c27.1-5.6,49.6-18.4,65.8-42
		c1.3,4.4,2.3,7.8,3.6,12.3C452.1,671.5,366.7,685.9,330.6,645z M478.3,397.2c-14.2-1.2-27.4,1-40.2,5.4
		c-13.1,4.6-25.3,10.5-35.3,20.4c-6.2,6-10.2,13-12.4,21.1c-3.6,13.2-0.8,24.8,9.5,33.9c5,4.4,10.9,7.8,16.3,11.8
		c9.5,7.1,19.8,13.4,28.2,21.6c23.7,22.9,19.5,67.4,2.4,89.1c-15.6,19.7-36.3,29.8-60.6,32.4c-15.2,1.6-30.6,1.6-45.3-4.2
		c-11.2-4.3-11.4-4.6-9.8-16.4c1.3-9.5,3.4-18.7,8-27.6c11.9,4.2,23.4,4.9,34.9,1.4c14-4.3,21.3-15.9,18.5-29
		c-2.1-9.7-9.2-14.9-17.8-17.9c-5.5-2-11.6-2.7-17.4-3.6c-16.5-2.4-32.5-6.3-47.5-14c-27.2-13.9-42-36.2-44.9-66.3
		c-2.9-29.8,6.5-56.4,24.6-79.7c36-46.4,84.1-72.4,141.5-82.5c10.6-1.9,17.6,1,23.1,10.3c15.5,26.5,23.8,55,25,85.6
		C479,391.5,478.5,394,478.3,397.2z M419.6,474.9c-11.2-6.9-11.1-11.4,0.7-19.8C428.8,464.1,428.8,464.5,419.6,474.9z M510.6,416.7
		c-2.8,0-5.6,0-8.5,0c-0.4-1.2-0.9-2.2-0.8-3.2c1.5-30.3-4.5-59.2-15.8-87.1c1.4,1.4,3.1,2.7,4,4.4c13.5,26.1,20.7,54,21.7,83.4
		C511.2,414.9,510.9,415.7,510.6,416.7z"
                />
                <path
                    d="M742.8,464.4c-3-2.1-6.5-3.8-9.9-5c-19.2-6.8-36.9-0.7-54.4,6.4c-4.6,1.9-5,4.8-1.1,8.1
		c5.8,5,12.1,9.5,18.4,13.9c2,1.4,5,1.4,7.3,2c14.8-1.9,28.2-6.6,40-15.2C747.6,471.3,747.4,467.7,742.8,464.4z"
                />
                <path
                    d="M804.3,264.5c-11,3.1-19,10.3-25.4,19.1c-6.3,8.7-14.3,14.9-23.9,19.3c-18.4,8.4-38,12.7-57.9,15.7
		c-3.2,0.5-6.7,1-9.5,2.6c-2.1,1.3-4.5,4.2-4.5,6.4c-0.1,2.3,2.1,5.3,4.2,6.7c5,3.2,10.5,2.3,14.8-1.2c13.8-11.3,30.3-17.1,47-22
		c16.1-4.7,32.5-8.2,48.7-12.3c12.4-3.2,23.9-7.9,30.7-19.8c0-2.2,0-4.4,0-6.6C821.8,263.5,815.2,261.4,804.3,264.5z"
                />
            </g>
        </svg>
    );
};
