import React from 'react';

type Props = {
    className?: string;
};

export const MarioKartEightIcon: React.FC<Props> = ({ className }) => {
    return (
        <svg viewBox="0 0 440.5 269.6" className={`icon${className ? className : ''}`}>
            <path d="M99,187.1L166.1,46.7l54.4,62.1,54.4-62.1,66.7,140.4-43.3,35.8-35.8-103.5-41.9,49.8-41.9-49.8-36.2,103.5-43.3-35.8Z" />
        </svg>
    );
};
