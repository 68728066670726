import React from 'react';
import { ApexLegendsIcon } from '../components/svg/games/ApexLegendsIcon';
import { BrawlhallaIcon } from '../components/svg/games/BrawlhallaIcon';
import { CallOfDutyIcon } from '../components/svg/games/CallOfDutyIcon';
import { ChessIcon } from '../components/svg/games/ChessIcon';
import { ClashRoyaleIcon } from '../components/svg/games/ClashRoyaleIcon';
import { CSGOIcon } from '../components/svg/games/CSGOIcon';
import { DefaultGameIcon } from '../components/svg/games/DefaultGameIcon';
import { Dota2Icon } from '../components/svg/games/Dota2Icon';
import { FIFA22Icon } from '../components/svg/games/FIFA22Icon';
import { FortniteIcon } from '../components/svg/games/FortniteIcon';
import { ForzaIcon } from '../components/svg/games/ForzaIcon';
import { HaloIcon } from '../components/svg/games/HaloIcon';
import { HearthstoneIcon } from '../components/svg/games/HearthstoneIcon';
import { IRacingIcon } from '../components/svg/games/IRacingIcon';
import { LeagueOfLegendsIcon } from '../components/svg/games/LeagueOfLegendsIcon';
import { LegendsOfRuneterraIcon } from '../components/svg/games/LegendsOfRuneterra';
import { MaddenIcon } from '../components/svg/games/MaddenIcon';
import { MTGIcon } from '../components/svg/games/MTGIcon';
import { NBA2KIcon } from '../components/svg/games/NBA2KIcon';
import { NHL22Icon } from '../components/svg/games/NHL22Icon';
import { OmegaStrikersIcon } from '../components/svg/games/OmegaStrikersIcon';
import { OverwatchIcon } from '../components/svg/games/OverwatchIcon';
import { PokemonUniteIcon } from '../components/svg/games/PokemonUniteIcon';
import { RainbowSixSiegeIcon } from '../components/svg/games/RainbowSixSiegeIcon';
import { RocketLeagueIcon } from '../components/svg/games/RocketLeagueIcon';
import { RogueCompanyIcon } from '../components/svg/games/RogueCompanyIcon';
import { SideSwipeIcon } from '../components/svg/games/SideSwipeIcon';
import { SmashBrosIcon } from '../components/svg/games/SmashBrosIcon';
import { SmiteIcon } from '../components/svg/games/SmiteIcon';
import { StreetFighterIcon } from '../components/svg/games/StreetFighterIcon';
import { TekkenIcon } from '../components/svg/games/TekkenIcon';
import { TFTIcon } from '../components/svg/games/TFTIcon';
import { ValorantIcon } from '../components/svg/games/ValorantIcon';
import { WarzoneIcon } from '../components/svg/games/WarzoneIcon';
import { WildRiftIcon } from '../components/svg/games/WildRiftIcon';
import { WoWIcon } from '../components/svg/games/WoWIcon';
import { Espin_Game_Definition_Enum } from '../generated/spin-graphql';
import { MarvelRivalsIcon } from 'components/svg/games/MarvelRivalsIcon';
import { MarioKartEightIcon } from 'components/svg/games/MarioKartEightIcon';
import { SplatoonIcon } from 'components/svg/games/SplatoonIcon';

export const getGameIconFromEnum = (gameDefinition: Espin_Game_Definition_Enum) => {
    switch (gameDefinition) {
        case Espin_Game_Definition_Enum.ApexLegends: {
            return <ApexLegendsIcon />;
        }
        case Espin_Game_Definition_Enum.Brawlhalla: {
            return <BrawlhallaIcon />;
        }
        case Espin_Game_Definition_Enum.CallOfDuty: {
            return <CallOfDutyIcon />;
        }
        case Espin_Game_Definition_Enum.Chess: {
            return <ChessIcon />;
        }
        case Espin_Game_Definition_Enum.ClashRoyale: {
            return <ClashRoyaleIcon />;
        }
        case Espin_Game_Definition_Enum.CodWarzone: {
            return <WarzoneIcon />;
        }
        case Espin_Game_Definition_Enum.Csgo: {
            return <CSGOIcon />;
        }
        case Espin_Game_Definition_Enum.Dota_2: {
            return <Dota2Icon />;
        }
        case Espin_Game_Definition_Enum.EaFc_24:
        case Espin_Game_Definition_Enum.Fifa_22: {
            return <FIFA22Icon />;
        }
        case Espin_Game_Definition_Enum.Fortnite: {
            return <FortniteIcon />;
        }
        case Espin_Game_Definition_Enum.Forza: {
            return <ForzaIcon />;
        }
        case Espin_Game_Definition_Enum.HaloInfinite: {
            return <HaloIcon />;
        }
        case Espin_Game_Definition_Enum.Hearthstone: {
            return <HearthstoneIcon />;
        }
        case Espin_Game_Definition_Enum.Iracing: {
            return <IRacingIcon />;
        }
        case Espin_Game_Definition_Enum.LeagueOfLegends: {
            return <LeagueOfLegendsIcon />;
        }
        case Espin_Game_Definition_Enum.LegendsOfRuneterra: {
            return <LegendsOfRuneterraIcon />;
        }
        case Espin_Game_Definition_Enum.Madden_24:
        case Espin_Game_Definition_Enum.Madden_22: {
            return <MaddenIcon />;
        }
        case Espin_Game_Definition_Enum.MagicTheGathering: {
            return <MTGIcon />;
        }
        case Espin_Game_Definition_Enum.Nba_2k22: {
            return <NBA2KIcon />;
        }
        case Espin_Game_Definition_Enum.Nhl_22: {
            return <NHL22Icon />;
        }
        case Espin_Game_Definition_Enum.Overwatch: {
            return <OverwatchIcon />;
        }
        case Espin_Game_Definition_Enum.PokemonUnite: {
            return <PokemonUniteIcon />;
        }
        case Espin_Game_Definition_Enum.RainbowSixSiege: {
            return <RainbowSixSiegeIcon />;
        }
        case Espin_Game_Definition_Enum.RocketLeague: {
            return <RocketLeagueIcon />;
        }
        case Espin_Game_Definition_Enum.RogueCompany: {
            return <RogueCompanyIcon />;
        }
        case Espin_Game_Definition_Enum.SideSwipe: {
            return <SideSwipeIcon />;
        }
        case Espin_Game_Definition_Enum.Smite: {
            return <SmiteIcon />;
        }
        case Espin_Game_Definition_Enum.StreetFighter: {
            return <StreetFighterIcon />;
        }
        case Espin_Game_Definition_Enum.SuperSmashBrosMelee: {
            return <SmashBrosIcon />;
        }
        case Espin_Game_Definition_Enum.SuperSmashBrosUltimate: {
            return <SmashBrosIcon />;
        }
        case Espin_Game_Definition_Enum.TeamfightTactics: {
            return <TFTIcon />;
        }
        case Espin_Game_Definition_Enum.Tekken: {
            return <TekkenIcon />;
        }
        case Espin_Game_Definition_Enum.Valorant: {
            return <ValorantIcon />;
        }
        case Espin_Game_Definition_Enum.LeagueOfLegendsWildRift: {
            return <WildRiftIcon />;
        }
        case Espin_Game_Definition_Enum.WorldOfWarcraft: {
            return <WoWIcon />;
        }
        case Espin_Game_Definition_Enum.OmegaStrikers: {
            return <OmegaStrikersIcon />;
        }
        case Espin_Game_Definition_Enum.MarvelRivals: {
            return <MarvelRivalsIcon />;
        }
        case Espin_Game_Definition_Enum.MarioKartEight: {
            return <MarioKartEightIcon />;
        }
        case Espin_Game_Definition_Enum.Splatoon: {
            return <SplatoonIcon />;
        }
        default:
            return <DefaultGameIcon />;
    }
};
